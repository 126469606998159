import * as React from "react";
import { PageProps } from "gatsby";
import Layout from "../../components/layout";
import Container from "../../components/ui-library/container/container";
import { AskAboutQuery_FbIntent } from "../../../graphql-types";
import CovidAnswers from "../../components/covid-19/covid-intent/covid-answers/covid-answers";
import PageContainer from "../../components/ui-library/page-container/page-container";
import PageHeading from "../../components/ui-library/page-heading/page-heading";
import SEO from "../../components/seo/seo";
import FAQSchema from "../../components/seo/faq-schema";
import SuggestedIntent from "../../components/common-page-sections/suggested-intent/suggested-intent";
import CollectQAndA from "../../components/common-page-sections/collect-q-and-a/collect-q-and-a";
import { LogoBunny } from "../../components/site/header/header";
import { SPECIAL_INTENTS } from "../../constants/special-intents";
import Navigation from "../../components/site/navigation/navigation";
import { Topic } from "../../types/topics";
import ECVBanner from "../../components/covid-19/third-party-banner/ecv-banner";

interface IntentData {
  intent: AskAboutQuery_FbIntent;
}

type Covid19AskIntentsProps = PageProps<null, IntentData>;

const Covid19AskIntents: React.FC<Covid19AskIntentsProps> = ({
  pageContext: { intent },
}) => {
  const matchingIntentKey = Object.keys(SPECIAL_INTENTS).find((k) => {
    return SPECIAL_INTENTS[k].name === intent.intentName;
  });

  return (
    <Layout
      topic={Topic.COVID19}
      description="coronavirus"
      headerUrl="/covid-19/"
      logoBunny={LogoBunny.FaceMask}
    >
      <SEO
        title={intent.displayName}
        description={(!!intent.messages && intent.messages[0]) || undefined}
        imageUrl={
          intent.imageUrl ||
          `/media/images/social-share/covid-19/${intent.alias}.jpg`
        }
      />

      <FAQSchema intent={intent} />

      <Navigation
        topic={Topic.COVID19}
        activeItem={
          matchingIntentKey ? SPECIAL_INTENTS[matchingIntentKey].nav : undefined
        }
      />

      <PageContainer>
        <PageHeading backUrl="/covid-19/">{intent.displayName}</PageHeading>

        <Container fullWidthOnMobile={true}>
          <CovidAnswers {...intent} />
        </Container>

        <Container>
          {!!intent.suggestedIntent && (
            <SuggestedIntent
              suggestedIntent={intent.suggestedIntent}
              topic={Topic.COVID19}
            />
          )}

          <ECVBanner />

          <CollectQAndA />
        </Container>
      </PageContainer>
    </Layout>
  );
};

export default Covid19AskIntents;
