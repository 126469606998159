import { Interpolation } from "@emotion/core";
import { grid } from "../../design-tokens/grid";
import { Colors } from "../../design-tokens/colors";
import { Border } from "../../design-tokens/borders";
import { Device } from "../../design-tokens/media-queries";
import { Fonts } from "../../design-tokens/fonts";
import { BoxShadow } from "../../design-tokens/box-shadow";

const root: Interpolation = {
  display: "block",
  maxWidth: 728,
  color: Colors.Black,
  backgroundColor: Colors.White,
  borderRadius: Border.Radius,
  padding: grid(2),
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: grid(4),
  boxShadow: BoxShadow.Default,
  transition: "all 0.2s ease",

  [Device.Mobile]: {
    paddingTop: grid(1),
    paddingBottom: grid(3),
  },

  [Device.DesktopTablet]: {
    display: "flex",
    alignItems: "center",
    height: grid(9),
    paddingLeft: grid(1.5),
    paddingRight: grid(3),

    ":hover": {
      transform: "translateY(-2px)",
      textDecoration: "none",
    },
  },
};

const image: Interpolation = {
  display: "block",
  width: grid(23),

  [Device.Mobile]: {
    marginLeft: grid(-1.5),
    marginBottom: grid(1),
  },

  [Device.DesktopTablet]: {
    marginRight: grid(2),
  },
};

const cta: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: grid(5),
  fontSize: 14,
  fontWeight: Fonts.Weight.SemiBold,
  backgroundColor: "#EEBC56",
  borderRadius: Border.Radius,

  [Device.Mobile]: {
    marginTop: grid(2),
  },

  [Device.DesktopTablet]: {
    flexShrink: 0,
    width: grid(14),
    marginLeft: grid(2),
  },
};

const styles = {
  root,
  image,
  cta,
};

export default styles;
