import * as React from "react";
import { Link } from "gatsby";
import { grid } from "../../design-tokens/grid";
import { getEventAttributes, EventCategory } from "../../../utils/analytics";

const CollectQAndA: React.FC = () => {
  return (
    <div css={{ textAlign: "center", paddingTop: grid(2) }}>
      <span css={{ whiteSpace: "nowrap" }}>
        Have a good question and answer?
      </span>{" "}
      <Link
        to="/suggest-question-and-answer/"
        css={{ whiteSpace: "nowrap" }}
        {...getEventAttributes({
          category: EventCategory.CollectQAndA,
          action: "Click - Suggest quality content",
          label: "Suggest quality content",
        })}
      >
        Suggest quality content
      </Link>
    </div>
  );
};

export default CollectQAndA;
