import * as React from "react";
import { Link } from "gatsby";
import Container from "../../ui-library/container/container";
import { NavItem } from "../../../types/nav-item";
import {
  COVID_NAV_ITEMS,
  HOME_NAV_ITEMS,
} from "../../../constants/top-navigations";
import { Topic } from "../../../types/topics";
import { getEventAttributes, EventCategory } from "../../../utils/analytics";
import styles from "./navigation.styles";

interface NavigationProps {
  activeItem?: NavItem;
  topic?: Topic;
}

const Navigation: React.FC<NavigationProps> = ({ activeItem, topic }) => {
  const items = topic === Topic.COVID19 ? COVID_NAV_ITEMS : HOME_NAV_ITEMS;

  return (
    <div css={styles.root}>
      <Container fullWidthOnMobile={true} css={styles.container}>
        {items.map((i) => (
          <Link
            key={i.label}
            to={i.url}
            css={[
              styles.tab,
              activeItem?.label === i.label && styles.activeTab,
            ]}
            {...getEventAttributes({
              category: EventCategory.Site_Navigate,
              action: `Click - ${i.label}`,
              label: i.label,
            })}
          >
            {i.label}
          </Link>
        ))}
      </Container>
    </div>
  );
};

export default Navigation;
