import * as React from "react";
import { AskAboutQuery_FbIntent } from "../../../graphql-types";

interface SimpleQAndA {
  question: string;
  answer: string;
}

interface FAQSchemaProps {
  intent: AskAboutQuery_FbIntent;
}

const FAQSchema: React.FC<FAQSchemaProps> = ({ intent }) => {
  const question = intent.displayName;

  const mainEntity = [
    {
      "@type": "Question",
      name: question,
      acceptedAnswer: {
        "@type": "Answer",
        text: intent.messages,
      },
    },
  ];

  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity,
  };

  return <script type="application/ld+json">{JSON.stringify(schema)}</script>;
};

export default FAQSchema;
