import { Interpolation } from "@emotion/core";
import { lighten } from "polished";
import { grid } from "../../design-tokens/grid";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { Colors } from "../../design-tokens/colors";
import { Fonts } from "../../design-tokens/fonts";

const root: Interpolation = {
  backgroundColor: Colors.White,

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBackground,
  },
};

const container: Interpolation = {
  display: "flex",
};

const tab: Interpolation = {
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: grid(5),
  color: Colors.Black,
  transition: "all 0.2s ease",

  ":hover": {
    textDecoration: "none",
    backgroundColor: lighten(0.01, Colors.LightGrey),

    [DarkTheme]: {
      backgroundColor: lighten(0.02, Colors.DarkModeBlack),
    },
  },

  [DarkTheme]: {
    color: Colors.White,
  },

  [Device.Mobile]: {
    fontSize: "14px",
  },
};

const activeTab: Interpolation = {
  fontWeight: Fonts.Weight.Bold,
  backgroundColor: Colors.LightGrey,

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBlack,
  },
};

const styles = {
  root,
  container,
  tab,
  activeTab,
};

export default styles;
