import * as React from "react";
import Image from "../../ui-library/image/image";
import ECVLogo from "./ecv-logo.jpg";
import styles from "./ecv-banner.styles";

const ECVBanner: React.FC = () => {
  return (
    <a
      css={styles.root}
      href="https://www.endcoronavirus.org/"
      target="_blank"
      rel="noreferrer"
    >
      <div>
        <Image css={styles.image} src={ECVLogo} alt="EndCoronavirus.org" />
      </div>
      <div>
        We educate in order to end the outbreak of Coronavirus COVID-19.
      </div>
      <div css={styles.cta}>Learn more</div>
    </a>
  );
};

export default ECVBanner;
